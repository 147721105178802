import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';

import AdminLayout from 'layouts/admin';
import AuthLayout from 'layouts/auth';
import { createStandaloneToast } from '@chakra-ui/toast';
import { useEffect } from 'react';
import { signInService } from 'views/services/auth';
import { PrivateRoute } from 'components/PrivateRouter';

// eslint-disable-next-line react-refresh/only-export-components
export const { toast, ToastContainer } = createStandaloneToast({});

const App = () => {
  const navigate = useNavigate();

  useEffect(() => {
    signInService.sendRefreshRequest().catch(() => {
      localStorage.removeItem('accessToken');

      signInService.logout();
      navigate('/auth/sign-in');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isDark = localStorage.getItem('darkmode');
    if (isDark === 'true') {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, []);

  return (
    <>
      <Routes>
        <Route path="auth/*" element={<AuthLayout />} />
        <Route
          path="admin/*"
          element={
            <PrivateRoute>
              <AdminLayout />
            </PrivateRoute>
          }
        />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Navigate to="/admin" replace />
            </PrivateRoute>
          }
        />
      </Routes>
      <ToastContainer />
    </>
  );
};

export default App;
