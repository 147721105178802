import clsx from 'clsx';
import { ButtonHTMLAttributes } from 'react';

export const DangerButton = ({
  children,
  className,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      type="button"
      className={clsx(
        `flex items-center gap-2 rounded-xl bg-red-500
                  px-5 py-2 text-base font-medium text-white transition
                  duration-200 hover:bg-red-600 active:bg-red-700
                  disabled:pointer-events-none disabled:opacity-50 dark:bg-red-400
                  dark:text-white dark:hover:bg-red-300 dark:active:bg-red-200`,
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
};
