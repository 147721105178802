import { useFormContext } from 'react-hook-form';
import InputField from 'components/fields/InputField';
import TextArea from 'components/fields/TextArea';
import { GiveawayFormScheme } from '../../scheme/taksForm';
import { useState } from 'react';
import { Button } from 'components/button';
import { MdAdd } from 'react-icons/md';
import { Giveaway, GiveawayTask } from 'views/types/tasks';
import { GiveawayTaskItem } from '../GiveawayTask';
import { preventScrollOnNumberInput } from 'utils/input';

type TTaskFormProps = {
  isEdit: boolean;
  giveaway: Giveaway;
  setIsChangedImage: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpenTaskModal: React.Dispatch<React.SetStateAction<boolean>>;
  setTask: React.Dispatch<React.SetStateAction<GiveawayTask>>;
};

export const GiveawayForm = ({
  isEdit,
  giveaway,
  setIsChangedImage,
  setIsOpenTaskModal,
  setTask,
}: TTaskFormProps) => {
  const form = useFormContext<GiveawayFormScheme>();
  const [date, setDate] = useState<string>();

  if (!form) {
    throw new Error('useFormContext must be used inside a FormProvider');
  }

  const handleOpen = (isOpen: boolean, task?: GiveawayTask) => {
    setIsOpenTaskModal(isOpen);
    setIsChangedImage(true);
    if (task) {
      setTask(task);
    }
  };

  const handleChooseDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDate(e.target.value);
    e.target.blur();
  };

  const validateFutureDate = (value: string) => {
    const selectedDate = new Date(value);
    selectedDate.setHours(0, 0, 0, 0);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (selectedDate <= today) {
      return 'End date must be a future date';
    }

    return true;
  };

  return (
    <>
      <fieldset className="mb-[15px] flex flex-col">
        <InputField
          variant="auth"
          state={form.formState.errors.rewardAmount ? 'error' : undefined}
          extra="w-full mb-3"
          label="Rewards amount"
          placeholder="Enter rewards amount"
          id="rewardAmount"
          onWheel={preventScrollOnNumberInput}
          {...form.register('rewardAmount', {
            required: 'This field is required',
            pattern: {
              value: /^(?!0$)[0-9]+([.,]{1}[0-9]+)?$/g,
              message: 'Invalid number format',
            },
          })}
        />
        {form.formState.errors.rewardAmount && (
          <span className="text-sm text-red-500 dark:text-red-400">
            {form.formState.errors.rewardAmount.message}
          </span>
        )}
      </fieldset>

      <fieldset className="mb-[15px] flex flex-col">
        <InputField
          variant="auth"
          state={form.formState.errors.endDate ? 'error' : undefined}
          extra="w-full mb-3"
          label="End date"
          placeholder="Enter end date for this giveaway"
          id="endDate"
          type="date"
          value={date}
          onChange={handleChooseDate}
          onWheel={preventScrollOnNumberInput}
          {...form.register('endDate', {
            required: 'This field is required',
            validate: validateFutureDate,
          })}
        />
        {form.formState.errors.endDate && (
          <span className="text-sm text-red-500 dark:text-red-400">
            {form.formState.errors.endDate.message}
          </span>
        )}
      </fieldset>

      <div className="flex flex-col">
        <fieldset className="mb-[15px] flex flex-col">
          <TextArea
            variant="auth"
            state={form.formState.errors.description ? 'error' : undefined}
            extra="w-full mb-3"
            label="Description"
            placeholder="Enter giveaway description"
            id="description"
            {...form.register('description', {
              required: 'This field is required',
            })}
          />
          {form.formState.errors.description && (
            <span className="text-sm text-red-500 dark:text-red-400">
              {form.formState.errors.description.message}
            </span>
          )}
        </fieldset>
      </div>

      {isEdit && (
        <div>
          <p>Tasks:</p>
          <div className="flex flex-col mb-2 mt-2 gap-2">
            {!!giveaway?.tasks &&
              giveaway.tasks.map(taskItem => (
                <GiveawayTaskItem
                  task={taskItem}
                  key={taskItem.id}
                  handleOpenTaskModal={handleOpen}
                />
              ))}
          </div>

          <Button onClick={() => handleOpen(true)}>
            <MdAdd />
            Add task
          </Button>
        </div>
      )}
    </>
  );
};
