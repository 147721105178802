import { $api } from './interceptor';

export type TCountryData = {
  name: string;
  usersCount: number;
  usersPercent: number;
};

export type TLanguageData = {
  name: string;
  usersCount: number;
  usersPercent: number;
};

export type TUtmSourceData = {
  name: string;
  usersCount: number;
  usersPercent: number;
};

export type TGenderData = {
  maleCount: number;
  femaleCount: number;
  otherGenderCount: number;
  malePercent: number;
  femalePercent: number;
  otherGenderPercent: number;
};

export type TCountPercentData = {
  usersCount: number;
  usersPercent: number;
};

export type TDevicesData = {
  iosCount: number;
  androidCount: number;
  otherPlatformsCount: number;
  iosPercent: number;
  androidPercent: number;
  otherPlatformsPercent: number;
};

export type TAnaliticsData = {
  availableUsersCount: number;
  completedUsersCount: number;
  notCompletedUsersCount: number;
  genderData: TGenderData;
  countriesData: TCountryData[];
  languagesData: TLanguageData[];
  tgPremiumData: TCountPercentData;
  utmSourcesData: TUtmSourceData[];
  walletsData: TCountPercentData;
  usersFilledBioData: TCountPercentData;
  devicesData: TDevicesData;
  selectedCountriesData: TCountryData[];
  usersAvgTgAge: string | null;
};

export type TExtraAnaliticsDataTypes =
  | 'age'
  | 'points'
  | 'accountAge'
  | 'completedTasks'
  | 'referrals';

export type TAnaliticsTypes = 'task' | 'giveaway' | 'utmSource' | 'empty';

type TExtraAnaliticsOptions = {
  dataType: TExtraAnaliticsDataTypes;
  analityctsType: TAnaliticsTypes;
  itemId: string;
  from?: number;
  to?: number | '';
};

const analiticsApi = () => {
  const getExtraAnalitics = async (options: TExtraAnaliticsOptions) => {
    const idOption =
      options.analityctsType === 'empty'
        ? ''
        : `&${options.analityctsType}Id=${options.itemId}`;
    const { data } = await $api.get<{ usersCount: number }>(
      `/api/users/specified-statistic?dataType=${options.dataType}${idOption}&fromValue=${options.from ?? ''}&toValue=${options.to ?? ''}`
    );
    return data;
  };

  const getGiveawayStats = async (id: string) => {
    const { data } = await $api.get<TAnaliticsData>(
      `/api/giveaways/stats/${id}`
    );
    return data;
  };

  const getTasksStats = async (id: string) => {
    const { data } = await $api.get<TAnaliticsData>(`/api/tasks/stat/${id}`);
    return data;
  };

  const getDateDifference = (date: string) => {
    const now = new Date();
    const then = new Date(date);
    const diff = now.getTime() - then.getTime();
    const months = Math.floor(diff / (1000 * 60 * 60 * 24 * 30));
    const years = Math.floor(months / 12);
    const finalMonths = months - years * 12;

    return `${years} y. ${finalMonths} m.`;
  };

  const convertToTimestamp = (dateString: string): number => {
    return new Date(dateString).getTime();
  };

  return {
    getExtraAnalitics,
    getGiveawayStats,
    getTasksStats,
    getDateDifference,
    convertToTimestamp,
  };
};

export const analiticsService = analiticsApi();
