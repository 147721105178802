import { ReactNode } from 'react';

function Card(props: {
  extra?: string;
  children?: ReactNode;
  [x: string]: unknown;
}) {
  const { extra, children, ...rest } = props;
  return (
    <div
      className={`relative flex flex-col rounded-[15px] border-[1px] border-gray-200 bg-white bg-clip-border shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none ${extra}`}
      {...rest}
    >
      {children}
    </div>
  );
}

export default Card;
