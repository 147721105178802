import Card from 'components/card';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ButtonSM } from '../../../../../components/button/Small';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { Button } from 'components/button';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { MdAdd } from 'react-icons/md';
import { useDebounceValue } from 'usehooks-ts';
import { Giveaway } from 'views/types/tasks';
import { TaskDelete } from '../GiveawayDelete';
import { giveawayService } from 'views/services/giveawayService';
import { giveawayStore } from 'views/store/useGiveawayStore';
import { GiveawayDialog } from '../GiveawayDialog';
import { CSVLink } from 'react-csv';
import { User } from 'views/types/typeUser';
import { usersService } from 'views/services/usersService';
import {
  EAnaliticDataTypeEnum,
  useAnaliticsStore,
} from 'views/store/useAnaliticsStore';

function TasksTable() {
  const { setAnalitic } = useAnaliticsStore();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [totalUsersCount, setTotalUsersCount] = useState<number>();
  const downloadCsvBtnRef = useRef<
    HTMLButtonElement & { link: HTMLAnchorElement | null }
  >(null);
  const [isExportLoading, setIsExportLoading] = useState<string | undefined>();
  const [resultsListForExport, setResultsListForExport] = useState<User[]>();
  const [isLoading, setIsLoading] = useState(true);
  const { giveawayList: tableData } = giveawayStore();

  const refreshGiveawayList = async () => {
    try {
      await giveawayService.getGiveaways();
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoadingResultsList = async (giveawayId: string) => {
    setIsExportLoading(giveawayId);
    const { data } = await giveawayService.getResultsList(giveawayId);
    const listForExport =
      data?.map((user: User, i: number) => [
        i + 1,
        user?.telegramUserId || 'Unknown',
        user?.telegramName || 'Unknown',
        user?.username || 'Unknown',
        user?.wallet?.address || 'Unknown',
      ]) || [];
    listForExport.unshift([
      '#',
      'TELEGRAM ID',
      'TELEGRAM NAME',
      'TELEGRAM USERNAME',
      'WALLET ADDRESS',
    ]);
    setResultsListForExport(listForExport);
    setIsExportLoading(undefined);
  };

  useEffect(() => {
    refreshGiveawayList();
    usersService
      .getUsersStatistic()
      .then(stat => setTotalUsersCount(stat.totalCount || 0));
  }, []);

  useEffect(() => {
    if (resultsListForExport) {
      downloadCsvBtnRef.current.link.click();
      setResultsListForExport(undefined);
    }
  }, [resultsListForExport]);

  const convertDate = (date: string) => {
    const d = new Date(date);
    return d.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('rewardAmount', {
        id: 'rewardAmount',
        enableSorting: false,
        header: () => (
          <p className="w-[100px] text-sm font-bold text-gray-600 dark:text-white">
            Award (points)
          </p>
        ),
        cell: info => (
          <p className="w-[100px] text-sm font-bold text-navy-700 dark:text-white">
            {info.cell.getValue()}
          </p>
        ),
      }),
      columnHelper.accessor('doneUsersAmount', {
        id: 'doneUsersAmount',
        enableSorting: false,
        header: () => (
          <p className="w-[140px] text-sm font-bold text-gray-600 dark:text-white">
            Users completed
          </p>
        ),
        cell: info => (
          <p className="w-[140px] text-sm font-bold text-navy-700 dark:text-white">
            {info.cell.getValue()}
            {totalUsersCount ? ` / ${totalUsersCount}` : ''}
          </p>
        ),
      }),
      columnHelper.accessor('description', {
        id: 'description',
        enableSorting: false,
        header: () => (
          <p className="w-[400px] text-sm font-bold text-gray-600 dark:text-white">
            Description
          </p>
        ),
        cell: info => (
          <p className="w-[400px] text-sm font-bold text-navy-700 dark:text-white truncate">
            {info.cell.getValue()}
          </p>
        ),
      }),
      columnHelper.accessor('id', {
        id: 'id',
        enableSorting: false,
        header: () => (
          <p className="w-[200px] text-sm font-bold text-gray-600 dark:text-white"></p>
        ),
        cell: info => (
          <Button
            disabled={isExportLoading == info.cell.getValue()}
            onClick={() => {
              handleLoadingResultsList(info.cell.getValue());
            }}
          >
            show results.csv
          </Button>
        ),
      }),
      columnHelper.accessor('isActive', {
        id: 'isActive',
        enableSorting: false,
        header: () => (
          <p className="w-[100px] text-sm font-bold text-gray-600 dark:text-white">
            Status
          </p>
        ),
        cell: info => (
          <p className="w-[100px] text-sm font-bold text-navy-700 dark:text-white">
            {info.cell.getValue() ? 'Active' : 'Inactive'}
          </p>
        ),
      }),
      columnHelper.display({
        id: 'actions',
        header: () => (
          <p className="w-1/3 text-sm font-bold text-gray-600 dark:text-white">
            Actions
          </p>
        ),
        cell: info => (
          <div className="flex gap-2 font-bold">
            <GiveawayDialog giveaway={info.row.original} formType="giveaway">
              <ButtonSM variant="primary">Edit</ButtonSM>
            </GiveawayDialog>
            <TaskDelete giveaway={info.row.original} />
            <ButtonSM
              variant="primary"
              onClick={() =>
                setAnalitic(
                  EAnaliticDataTypeEnum.giveaway,
                  info.row.original.id,
                  convertDate(info.row.original.endDate)
                )
              }
            >
              Analytics
            </ButtonSM>
          </div>
        ),
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isExportLoading, totalUsersCount]
  );

  const [data] = useDebounceValue(tableData ?? [], 100);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <>
      <div className="mb-8 mt-8 flex items-center justify-between">
        <div className="flex gap-2 ml-auto">
          <GiveawayDialog formType="giveaway">
            <Button>
              <MdAdd />
              Create giveaway
            </Button>
          </GiveawayDialog>
        </div>
      </div>
      <Card extra={'w-full max-h-[63rem] overflow-auto px-6 pb-6 grow'}>
        <header className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            Giveaway Dashboard
          </div>
        </header>

        <div className="mt-8 overflow-x-auto xl:overflow-x-scroll">
          <table className="w-full">
            <thead className="sticky top-0 z-10">
              {table.getHeaderGroups().map(headerGroup => (
                <tr
                  key={headerGroup.id}
                  className="!border-px !border-gray-400"
                >
                  {headerGroup.headers.map(header => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        onClick={header.column.getToggleSortingHandler()}
                        className={clsx(
                          'bg-white pb-2 pr-4 pt-4 text-start dark:bg-navy-800',
                          header.column.getCanSort() && 'cursor-pointer'
                        )}
                      >
                        <div className="items-center justify-between text-xs text-gray-200">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: '',
                            desc: '',
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {isLoading && (
                <tr>
                  <td
                    className="absolute left-1/2 -translate-x-1/2"
                    colSpan={8}
                  >
                    <p className="flex justify-center py-10">
                      <AiOutlineLoading3Quarters className="h-10 w-10 animate-spin" />
                    </p>
                  </td>
                </tr>
              )}
              {!isLoading && (tableData.length ?? 0) <= 0 && (
                <tr>
                  <td
                    className="absolute left-1/2 -translate-x-1/2"
                    colSpan={8}
                  >
                    <p className="py-10 text-center font-bold uppercase">
                      No data
                    </p>
                  </td>
                </tr>
              )}
              {!isLoading &&
                !!tableData &&
                tableData.length > 0 &&
                table.getRowModel().rows.map(row => {
                  return (
                    <tr
                      key={row.id}
                      className="[&_td]:odd:bg-gray-50 [&_td]:odd:dark:bg-navy-900"
                    >
                      {row.getVisibleCells().map(cell => {
                        return (
                          <td
                            key={cell.id}
                            className="border-white/0 py-2 pr-4 first:rounded-l-lg last:rounded-r-lg last:pr-0"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </Card>
      <CSVLink
        data={resultsListForExport || []}
        filename={'giveaway-results'}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ref={downloadCsvBtnRef as any}
      />
    </>
  );
}

export default TasksTable;
const columnHelper = createColumnHelper<Giveaway>();
