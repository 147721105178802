import { Giveaway } from 'views/types/tasks';
import { create, StateCreator, useStore } from 'zustand';
type TGiveawayStoreState = {
  giveawayList: Giveaway[];
  clear: () => void;
};

export const createGiveawayStoreFunction: StateCreator<
  TGiveawayStoreState
> = set => ({
  giveawayList: [],
  clear: () =>
    set({
      giveawayList: [],
    }),
});

export const giveawayStore = create<TGiveawayStoreState>(
  createGiveawayStoreFunction
);

export const useGiveawayStore = () => useStore(giveawayStore);
