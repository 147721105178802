import { formatNumber } from 'utils/formatNumber';

export const UserCountAndPercent = ({
  count,
  percent,
}: {
  count: number;
  percent: number;
}) => (
  <>
    <p>Users Count: {formatNumber(count)}</p>
    <p>Users Percent: {formatNumber(percent || 0)}%</p>
  </>
);
