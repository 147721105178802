import { ButtonSM } from 'components/button/Small';
import { useState } from 'react';
import { tasksService } from 'views/services/tasksService';
import { Task } from 'views/types/tasks';

export interface TaskDeleteProps {
  task: Task;
  onSuccess: () => void;
}

export const TaskDelete = ({ task, onSuccess }: TaskDeleteProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleDelete = async () => {
    setIsSubmitting(true);
    try {
      if (window.confirm(`Are you sure you want to delete?`)) {
        await tasksService.deleteTask(task.id);
        onSuccess();
      }
    } catch (e) {
      console.warn(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ButtonSM variant="danger" disabled={isSubmitting} onClick={handleDelete}>
      Delete
    </ButtonSM>
  );
};
