import { $api } from './interceptor';

export interface Country {
  code: string;
}

const getLanguages = async () => {
  try {
    const { data } = await $api.get('/api/languages');

    return data;
  } catch (e) {
    console.log(e);
  }
};

export default getLanguages;
