const PREDEFINED_COLORS = [
  'rgb(209, 190, 124)',
  'rgb(95, 73, 138)',
  'rgb(108, 138, 118)',
];

export const generateColors = (length: number): string[] => {
  const usedColors = new Set(PREDEFINED_COLORS);

  const dynamicColors = (): string => {
    const getRandomValue = () => Math.floor(Math.random() * 255);

    const r = getRandomValue();
    const g = getRandomValue();
    const b = getRandomValue();

    const color = `rgb(${r},${g},${b})`;

    if (!usedColors.has(color)) {
      usedColors.add(color);
      return color;
    } else {
      return dynamicColors();
    }
  };

  const colors = [...PREDEFINED_COLORS];

  for (let i = 0; i < length; i++) {
    colors.push(dynamicColors());
  }

  return colors;
};
