import { $api } from './interceptor';

export interface Country {
  code: string;
  name: string;
}

const getCountries = async () => {
  try {
    const { data } = await $api.get('/api/countries');

    return data;
  } catch (e) {
    console.log(e);
  }
};

export default getCountries;
