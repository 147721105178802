import { MdGroup } from 'react-icons/md';

import Widget from 'components/widget/Widget';
import UsersTable from 'views/admin/default/components/UsersTable';
import { HiOutlinePuzzlePiece } from 'react-icons/hi2';
import useSWR from 'swr';
import { usersService } from 'views/services/usersService';
import { combosService } from 'views/services/comboService';
import { Button } from 'components/button';
import {
  EAnaliticDataTypeEnum,
  useAnaliticsStore,
} from 'views/store/useAnaliticsStore';
import { useEffect } from 'react';
import { DurovFaceRenderer } from './components/DurovFaceRenderer';

const Dashboard = () => {
  const {
    setAnalitic,
    setAppStatistics,
    type: analiticsType,
  } = useAnaliticsStore();

  const { data: statistics, isLoading: isStatisticLoading } = useSWR(
    '/users-statistics',
    () => usersService.getUsersStatistic(),
    {
      refreshInterval: 5000,
    }
  );

  const { data: secretCombos, isLoading: isComboLoading } = useSWR(
    '/secret-combo/sequence',
    () => combosService.getSecretCombo(),
    {
      refreshInterval: 5000,
    }
  );

  const handleAnalyticsClick = () => {
    setAnalitic(EAnaliticDataTypeEnum.app, 'null', 'App statistics');
  };

  useEffect(() => {
    if (analiticsType === 'app') {
      setAppStatistics(statistics);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statistics, analiticsType]);

  return (
    <div>
      <div className="mt-3 w-full flex flex-col md:flex-row gap-4 justify-between">
        <Widget
          icon={<MdGroup className="h-7 w-7" />}
          title={'Total Users'}
          subtitle={
            isStatisticLoading
              ? 'Loading...'
              : statistics?.totalCount.toString()
          }
        />
        <Widget
          icon={<MdGroup className="h-7 w-7" />}
          title={'Daily Active Users'}
          subtitle={
            isStatisticLoading
              ? 'Loading...'
              : statistics?.activeYesterdayCount.toString()
          }
        />
        <Widget
          icon={<HiOutlinePuzzlePiece className="h-7 w-7" />}
          title={'Puzzle Durov Combo'}
          extra="h-[105px]"
          subtitle={
            isComboLoading ? (
              'Loading...'
            ) : (
              <div className="flex">
                {secretCombos?.map(item => (
                  <DurovFaceRenderer
                    index={item.secretOrder}
                    bgColor={item.backgroundColor}
                    img={item.imageSvg}
                  />
                ))}
              </div>
            )
          }
        />
        <Button
          className="ml-auto mt-auto h-fit"
          onClick={handleAnalyticsClick}
        >
          Analytics
        </Button>
      </div>
      <div className="mt-5 grid grid-cols-1 gap-5">
        <div className="w-full">
          <UsersTable />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
