import {
  TQuestion,
  TQuizItem,
  TStory,
  TStoryTask,
  TTicketTask,
} from 'views/services/tasksService';

export enum TaskType {
  TELEGRAM = 'TELEGRAM',
  SNAPSHOT = 'SNAPSHOT',
  YOUTUBE = 'YOUTUBE',
  QUIZ = 'QUIZ',
  COMMON = 'COMMON',
  STORY = 'STORY',
  TRANSACTION = 'TRANSACTION',
  WALLET = 'WALLET',
  REFERRAL = 'REFERRAL',
  BUYTICKETS = 'BUY_TICKET',
  OWNTICKETS = 'OWN_TICKET',
  TRAFFIC = 'TRAFFIC',
}

export enum TaskCategoriesEnum {
  MAIN = 'MAIN',
  DAILY = 'DAILY',
  PARTNERS = 'PARTNERS',
}

export enum TelegramTaskType {
  SUBSCRIBE = 'SUBSCRIBE',
}

export enum TwitterTaskType {
  SUBSCRIBE = 'SUBSCRIBE',
  LIKE = 'LIKE_POST',
  RETWEET_POST = 'RETWEET_POST',
}
export enum SnapshotTaskStatusesEnum {
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  REVIEW = 'REVIEW',
  REJECTED = 'REJECTED',
}

export enum TaskStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum TasksTab {
  PUSSY = 'Pussy',
  PARTNERS = 'Partners',
}

export interface Task {
  id: string;
  type: TaskType;
  inactiveDays?: number;
  withoutGiveawayOnly?: boolean;
  category: TaskCategoriesEnum;
  availableFromLevel: number;
  title: string;
  rewardPointsAmount: number;
  description: string;
  status: TaskStatus;
  redirectUrl: string;
  telegramResourceId?: number;
  secretWord?: string;
  order: number;
  iconUrl?: string;
  inviteCount?: number;
  usersCompletedCount?: number;
  usersTotalCount?: number;
  targetLanguages?: string[] | null;
  targetCountries?: { id: string; code: string; name: string }[] | null;
  isTgPremiumOnly: boolean;
  isTgStandardOnly: boolean;
  isUsersWithWalletOnly: boolean;
  isUsersWithoutWalletOnly: boolean;
  quizQuestions?: TQuestion[];
  stories?: TStory[];
  quiz?: TQuizItem;
  influencerTasks?: InfluencerTask[];
  transactionTask?: {
    transactionWalletAddress: string;
    transactionAmount: number;
  };
  buyTicketTask?: TTicketTask;
  transactionWalletAddress?: string;
  transactionAmount?: number;
  targetPlatforms?: EPlatforms[];
  starsPrice?: number;
  ticketsAmount?: number;
  partnerUrl?: string;
}

export type InfluencerTask = {
  influencerId: string;
  redirectUrl: string;
  order?: number;
  referralCount?: number;
  influencerUsername?: string | undefined;
};

export type Giveaway = {
  id: string;
  title: string;
  imageUrl: string;
  description: string;
  rewardAmount: string;
  isActive: boolean;
  isTgPremiumOnly: boolean;
  isTgStandardOnly: boolean;
  approximateTime: string;
  endDate: string; // ISO string format
  daysLeft: number;
  tasks: GiveawayTask[];
  targetLanguages?: string[] | null;
  targetCountries?: string[] | null;
  doneTasksAmount: number;
  doneUsersAmount: number;
  targetedUsers: number;
  withNotification: boolean;
};

export type GiveawayTask = {
  id: string;
  type: TaskType;
  title: string;
  description: string;
  rewardPointsAmount: number;
  redirectUrl: string;
  taskStatus: TaskStatus;
  giveawayId: string;
  quiz: TQuizItem;
  quizQuestions?: TQuestion[];
  createdAt: string;
  userTaskId?: string;
  status?: SnapshotTaskStatusesEnum;
  userId?: string;
  userReferrals?: number;
  inviteCount?: number;
  referrals?: number;
  secretWord?: string;
  storyTask: TStoryTask;
  stories?: TStory[];
  targetLanguages?: string[] | null;
  targetCountries?: { id: string; code: string; name: string }[] | null;
  iconUrl?: string;
  targetPlatforms?: EPlatforms[];
  transactionTask?: {
    transactionWalletAddress: string;
    transactionAmount: number;
  };
  transactionWalletAddress?: string;
  transactionAmount?: number;
  buyTicketTask?: TTicketTask;
  starsPrice?: number;
  ticketsAmount?: number;
  partnerUrl?: string;
};

export enum EPlatforms {
  ios = 'ios',
  android = 'android',
}
