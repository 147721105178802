import { isExpired } from 'react-jwt';

/**
 * Get the access token from local storage
 * @returns {string} The access token
 * @throws {Error} If no access token is found or if the access token has expired
 */
export const getAccessToken = (): string => {
  const accessToken = localStorage.getItem('accessToken');

  if (!accessToken) {
    throw new Error('No access token found');
  }

  if (isExpired(accessToken)) {
    throw new Error('Access token has expired');
  }

  return accessToken;
};
