import UTMSourcesTable from './components/UTMSourceTable';

const UTMSourcesView = () => {
  return (
    <div className="grid h-full grid-cols-1 gap-5">
      <div className="col-span-1 h-full w-full rounded-xl 2xl:col-span-1">
        <UTMSourcesTable />
      </div>
    </div>
  );
};
export default UTMSourcesView;
