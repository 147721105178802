import Dropdown from 'components/dropdown';
import { useState } from 'react';
import { FiAlignJustify } from 'react-icons/fi';
import { MdPerson } from 'react-icons/md';
import { RiMoonFill, RiSunFill } from 'react-icons/ri';
import { Link, useNavigate } from 'react-router-dom';
import { signInService } from 'views/services/auth';

const Navbar = ({
  isSideNavOpen,
  onSideNavOpenChange,
  brandText,
}: {
  isSideNavOpen: boolean;
  onSideNavOpenChange: (isOpen: boolean) => void;
  brandText: string;
  secondary?: boolean | string;
}) => {
  const [darkmode, setDarkmode] = useState(
    localStorage.getItem('darkmode') === 'true'
  );
  const navigate = useNavigate();

  const handleLogout = async () => {
    await signInService.logout();
    navigate('/auth/sign-in');
  };

  return (
    <nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d]">
      <div className="ml-[6px]">
        <div className="h-6 w-[224px] pt-1">
          <a
            className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            href=" "
          >
            Pages
            <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
              {' '}
              /{' '}
            </span>
          </a>
          <Link
            className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            to="#"
          >
            {brandText}
          </Link>
        </div>
        <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
          <Link
            to="#"
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            {brandText}
          </Link>
        </p>
      </div>

      <div className="relative mt-[3px] flex h-[61px] items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:gap-1 xl:gap-2">
        {/* Profile & Dropdown */}
        <button
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
          onClick={() => onSideNavOpenChange(!isSideNavOpen)}
        >
          <FiAlignJustify className="h-5 w-5" />
        </button>
        <button
          className="cursor-pointer text-gray-600"
          onClick={() => {
            if (darkmode) {
              setDarkmode(false);
              localStorage.setItem('darkmode', 'false');
              document.body.classList.remove('dark');
            } else {
              setDarkmode(true);
              localStorage.setItem('darkmode', 'true');
              document.body.classList.add('dark');
            }
          }}
        >
          {darkmode ? (
            <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white" />
          ) : (
            <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white" />
          )}
        </button>

        <Dropdown
          button={
            <button className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-600">
              <MdPerson className="h-8 w-8 text-white" />
            </button>
          }
          children={
            <div className="h-25 flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="ml-4 mt-3">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    👋 Hey
                  </p>{' '}
                </div>
              </div>
              <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />

              <div className="my-4 ml-4">
                <button
                  className="mt-3 text-sm font-medium text-red-500 hover:text-red-500"
                  onClick={handleLogout}
                >
                  Log Out
                </button>
              </div>
            </div>
          }
          classNames={'py-2 top-8 -left-[180px]'}
        />
      </div>
    </nav>
  );
};

export default Navbar;
