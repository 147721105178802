import TopCreatorTable from './components/TasksTable';

const TasksView = () => {
  return (
    <div className="grid h-full grid-cols-1 gap-5">
      <div className="col-span-1 h-full w-full rounded-xl 2xl:col-span-1">
        <TopCreatorTable />
      </div>
    </div>
  );
};

export default TasksView;
