import { UTMSource } from 'views/types/utm';

export const transformUTMName = (utm: UTMSource['name']) => {
  let name = '';
  utm = utm.trim();

  for (let i = 0; i < utm.length; i++) {
    if (utm[i] === ' ') {
      name += utm[i + 1].toUpperCase();
      i++;
    } else {
      name += utm[i];
    }
  }
  return encodeURIComponent(name);
};
