import { MouseEvent } from 'react';

export const preventScrollOnNumberInput = (e: MouseEvent<HTMLInputElement>) => {
  // Prevent the input value change
  (e.target as HTMLInputElement).blur();

  // Prevent the page/container scrolling
  e.stopPropagation();

  // Refocus immediately, on the next tick (after the current
  setTimeout(() => {
    (e.target as HTMLInputElement).focus({
      preventScroll: true,
    });
  }, 0);
};
