import { useLayoutEffect, useState } from 'react';
import {
  adminSettingsService,
  ESettingTypesEnum,
  TAdminSettings,
} from 'views/services/adminSettings';

export const ApproveTypeCheckbox = () => {
  const [adminSettings, setAdminSettings] = useState<TAdminSettings>();

  const onUpdateAdminSettings = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.currentTarget.checked;
    const updatedValue = await adminSettingsService.updateAdminSettings({
      type: ESettingTypesEnum.AUTO_APPROVE,
      isEnabled: !value,
    });

    if (updatedValue) {
      setAdminSettings(updatedValue);
    }
  };

  useLayoutEffect(() => {
    adminSettingsService
      .getAdminSettings(ESettingTypesEnum.AUTO_APPROVE)
      .then(res => !!res && setAdminSettings(res));
  }, []);

  return (
    <div className="mt-4 mr-8 flex justify-end items-center h-fit">
      <p className="mr-4 dark:text-white">Manual approve</p>
      <input
        type="checkbox"
        checked={adminSettings ? !adminSettings?.isEnabled : false}
        onChange={onUpdateAdminSettings}
        className="accent-brand-500 dark:accent-brand-400"
      />
    </div>
  );
};
