import { Button } from 'components/button';
import Select from 'components/fields/Select';
import React from 'react';
import {
  MdArrowBackIosNew,
  MdArrowForwardIos,
  MdFirstPage,
  MdLastPage,
} from 'react-icons/md';

interface PaginationProps {
  currentPage: number;
  totalItems: number;
  totalPages: number;
  onPageChange: (page: number) => void;

  itemsPerPage: number;
  onItemsPerPageChange: (itemsPerPage: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalItems,
  totalPages,
  onPageChange,
  itemsPerPage,
  onItemsPerPageChange,
}) => {
  return (
    <div className="mt-auto flex flex-col justify-start gap-2 pt-5 lg:flex-row lg:items-center">
      <div className="flex flex-col gap-2 md:flex-row md:items-center">
        <Button disabled={currentPage <= 0} onClick={() => onPageChange(0)}>
          <MdFirstPage /> First
        </Button>
        <Button
          disabled={currentPage <= 0}
          onClick={() => onPageChange(currentPage - 1)}
        >
          <MdArrowBackIosNew /> Previous
        </Button>
        <Button
          disabled={currentPage + 1 >= (totalPages ?? 1)}
          onClick={() => onPageChange(currentPage + 1)}
        >
          Next
          <MdArrowForwardIos />
        </Button>

        <Button
          disabled={currentPage + 1 >= (totalPages ?? 1)}
          onClick={() => onPageChange(totalPages - 1 ?? 1)}
        >
          Last
          <MdLastPage />
        </Button>

        <Select
          value={itemsPerPage}
          onChange={e => onItemsPerPageChange(Number(e.target.value))}
          className="m-0 h-10 p-2"
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </Select>
      </div>
      <div className="flex flex-col items-center gap-4 text-gray-700 dark:text-gray-200 lg:ml-auto md:flex-row">
        <div>Current page: {Math.max(currentPage, 1)}</div>
        <div>Total items: {totalItems ?? 0}</div>
        <div>Total pages: {Math.max(totalPages ?? 1, 1)}</div>
      </div>
    </div>
  );
};

export default Pagination;
