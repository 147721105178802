import { AxiosError } from 'axios';
import InputField from 'components/fields/InputField';
import { useForm } from 'react-hook-form';
// import { useNavigate } from "react-router-dom";

import { LogInForm } from '../admin/marketplace/scheme/taksForm';
import { signInService } from 'views/services/auth';
import { useNavigate } from 'react-router-dom';

export default function SignIn() {
  const navigate = useNavigate();

  const form = useForm<LogInForm>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  if (!form) {
    throw new Error('useFormContext must be used inside a FormProvider');
  }

  const handleSubmit = async (data: LogInForm) => {
    try {
      await signInService.sendLoginRequest(data.email, data.password);
      navigate('/admin');
    } catch (error) {
      if (error instanceof AxiosError) {
        form.setError('root', {
          type: 'custom',
          message: error.response?.data.message || 'Something went wrong',
        });

        if (error.response?.data.message === 'Invalid credentials') {
          form.setError('email', {
            type: 'custom',
          });
          form.setError('password', {
            type: 'custom',
          });
        }
      }
    }
  };

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        className="mx-auto mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:max-w-[420px] lg:pl-0"
      >
        <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
          <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
            Sign In
          </h4>

          {/* Email */}
          <fieldset className="mb-[15px] flex flex-col">
            <InputField
              variant="auth"
              state={form.formState.errors.email ? 'error' : undefined}
              extra="mb-3"
              label="Login*"
              placeholder=""
              id="email"
              type="text"
              autoComplete="username"
              {...form.register('email', {
                required: 'This field is required',
                onChange: () => {
                  form.clearErrors('root');
                  form.clearErrors('email');
                },
              })}
            />
            {form.formState.errors.email && (
              <span className="text-sm text-red-500 dark:text-red-400">
                {form.formState.errors.email.message}
              </span>
            )}
          </fieldset>

          {/* Password */}

          <fieldset className="mb-[15px] flex flex-col">
            <InputField
              variant="auth"
              state={form.formState.errors.password ? 'error' : undefined}
              extra="mb-3"
              label="Password*"
              placeholder="Min. 8 characters"
              id="password"
              type="password"
              autoComplete="current-password"
              {...form.register('password', {
                required: 'This field is required',
                minLength: {
                  value: 8,
                  message: 'Password must be at least 8 characters',
                },
                onChange: () => {
                  form.clearErrors('root');
                  form.clearErrors('password');
                },
              })}
            />
            {form.formState.errors.password && (
              <span className="text-sm text-red-500 dark:text-red-400">
                {form.formState.errors.password.message}
              </span>
            )}
          </fieldset>
          {form.formState.errors.root && (
            <span className="text-sm text-red-500 dark:text-red-400">
              {form.formState.errors.root.message}
            </span>
          )}
          <button
            disabled={form.formState.isSubmitting}
            className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 disabled:opacity-50 disabled:pointer-events-none"
          >
            Sign In
          </button>
        </div>
      </form>
    </div>
  );
}
