import * as Dialog from '@radix-ui/react-dialog';
import { ReactNode, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { MdClose } from 'react-icons/md';

import type { TUTMFormSchemeInfluencer } from '../../scheme/form';
import { UTMSourceForm } from './Form';
import { utmService } from 'views/services/utmService';
import type { TUMSourceInfluencer } from 'views/types/utm';

type TTaskDialogProps = {
  utm?: TUMSourceInfluencer;
  children: ReactNode;
  onOpenChange: (isOpen: boolean) => void;
  onSuccess?: () => void;
};

export const UTMSourceDialog = ({
  utm,
  children,
  onOpenChange,
  onSuccess,
}: TTaskDialogProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const isEdit = !!utm;

  const form = useForm<TUTMFormSchemeInfluencer>({
    values: {
      name: isEdit ? utm?.name : '',
      influencerId: isEdit ? utm?.influencerId : '',
      username: isEdit ? utm?.influencer?.username : '',
    },
  });

  const handleOpenChange = (isOpen: boolean) => {
    setIsOpen(isOpen);
    onOpenChange(isOpen);

    if (isOpen) return;
    form.reset();
  };

  const handleSubmit = async (data: TUTMFormSchemeInfluencer) => {
    try {
      if (isEdit) {
        await utmService.editUTMSource(utm.id, data);
      } else {
        await utmService.createUTMSource(data);
      }
      onSuccess?.();
      form.reset();
      handleOpenChange(false);
    } catch (error) {
      console.warn('Error creating task', error);
    }
  };

  return (
    <>
      <Dialog.Root open={isOpen} onOpenChange={handleOpenChange} modal>
        <Dialog.Trigger asChild>{children}</Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 z-50 bg-gray-800 bg-opacity-50" />
          <Dialog.Content
            onInteractOutside={e => {
              if ((e.target as HTMLElement).closest('.chakra-toast')) {
                e.preventDefault();
              }
            }}
            className="data-[state=open]:animate-contentShow fixed left-[50%] top-[50%] z-[60] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none dark:bg-gray-800 dark:text-white"
          >
            <form onSubmit={form.handleSubmit(handleSubmit)}>
              <Dialog.Title className="text-mauve12 mb-10 text-[17px] font-medium">
                {isEdit ? 'Edit UTM source' : 'Create utm source'}
              </Dialog.Title>
              <div className="max-h-[55dvh] grow overflow-y-auto overflow-x-hidden px-px">
                <FormProvider {...form}>
                  <UTMSourceForm isEdit={isEdit} />
                </FormProvider>
              </div>

              <div className="mt-[10px] flex flex-col">
                {form.formState.errors.root && (
                  <span className="text-sm capitalize text-red-500 dark:text-red-400">
                    {form.formState.errors.root.message}
                  </span>
                )}
                <button
                  type="submit"
                  className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 disabled:pointer-events-none disabled:opacity-50 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  disabled={
                    form.formState.isSubmitting || !form.formState.isDirty
                  }
                >
                  {form.formState.isSubmitting ? 'Saving...' : 'Save changes'}
                </button>
              </div>
            </form>
            <Dialog.Close asChild>
              <button
                type="button"
                className="text-violet11 hover:bg-violet4 focus:shadow-violet7 absolute right-[10px] top-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
                aria-label="Close"
              >
                <MdClose />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};
