import InputField from 'components/fields/InputField';
import { useEffect, useState } from 'react';
import { useDebounceValue } from 'usehooks-ts';
import {
  analiticsService,
  TAnaliticsTypes,
  TExtraAnaliticsDataTypes,
} from 'views/services/analitics';
import { UserCountAndPercent } from './UserCountAndPercent';
import { convertToTimestamp } from 'utils/date';

type TExtraProps = {
  resourceId: string;
  dataType: TExtraAnaliticsDataTypes;
  analityctsType: TAnaliticsTypes;
  title: string;
  totalUsers: number;
  maxValue?: number;
  floatAllowed?: boolean;
};

export const ExtraAnaliticField = ({
  analityctsType,
  dataType,
  resourceId,
  title,
  totalUsers,
  maxValue,
  floatAllowed = false,
}: TExtraProps) => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [min, setMin] = useState<string>('');
  const [max, setMax] = useState<string>('');
  const [debouncedMin] = useDebounceValue(min, 500);
  const [debouncedMax] = useDebounceValue(max, 500);
  const [count, setCount] = useState<number | null>(null);
  const [dateFrom, setDateFrom] = useState<string>('');
  const [dateTo, setDateTo] = useState<string>('');

  const isAvgTgAccountAge = dataType === 'accountAge';

  const handleChooseFromDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDateFrom(e.target.value);
  };

  const handleChooseToDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDateTo(e.target.value);
  };

  const isDateToSmaller =
    dateFrom && dateTo && new Date(dateFrom) > new Date(dateTo);
  const isMaxSmaller = min && max && +min > +max;
  const isMaxBiggerThanMaxValue = max && maxValue && +max > maxValue;
  const isMinBiggerThanMaxValue = min && maxValue && +min > maxValue;

  const handleInputWidth = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.style.width = `${e.target.value.length + 3}ch`;
  };

  const handleNumberInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    setter: (val: string) => void
  ) => {
    const value = e.target.value;

    const normalizedValue = floatAllowed
      ? value.replaceAll(',', '.')
      : value.replaceAll(',', '').replaceAll('.', '');

    const val = Number(normalizedValue);

    if (!isNaN(val) && val > 0) {
      setter(normalizedValue);
      handleInputWidth(e);
    }

    if (value === '') {
      setter('');
    }
  };

  const handleMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleNumberInput(e, setMin);
  };

  const handleMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleNumberInput(e, setMax);
  };

  useEffect(() => {
    if (debouncedMax || debouncedMin || dateFrom || dateTo) {
      const from = isAvgTgAccountAge
        ? convertToTimestamp(dateFrom)
        : debouncedMin;
      const to = isAvgTgAccountAge ? convertToTimestamp(dateTo) : debouncedMax;

      setIsDataLoading(true);

      analiticsService
        .getExtraAnalitics({
          dataType: dataType,
          analityctsType: analityctsType,
          itemId: resourceId,
          from: isNaN(+from) ? 0 : +from,
          to: isNaN(+to) ? '' : +to,
        })
        .then(data => setCount(data.usersCount))
        .finally(() => setIsDataLoading(false));
    } else {
      setCount(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedMin, debouncedMax, dateFrom, dateTo]);

  return (
    <div className="flex gap-8 items-end w-full">
      <div className="flex gap-2 items-center">
        <p className="max-w-[180px] min-w-[180px] text-right">{title}</p>

        {!isAvgTgAccountAge && (
          <div className="flex gap-2">
            <div>
              <InputField
                variant="auth"
                id="minAge"
                type="text"
                value={min}
                onChange={handleMinChange}
                extra="min"
                placeholder="From"
                state={isMinBiggerThanMaxValue ? 'error' : 'default'}
                style={{
                  minWidth: '60px',
                  width: '60px',
                  textAlign: 'center',
                }}
              />
              {!!isMinBiggerThanMaxValue && (
                <span>
                  <p className="text-red-500 text-xs ml-1">
                    Max value is {maxValue}
                  </p>
                </span>
              )}
            </div>

            <div>
              <InputField
                variant="auth"
                id="maxAge"
                type="text"
                value={max}
                state={
                  isMaxSmaller || isMaxBiggerThanMaxValue ? 'error' : 'default'
                }
                onChange={handleMaxChange}
                extra="max"
                placeholder="To"
                style={{
                  minWidth: '60px',
                  width: '60px',
                  textAlign: 'center',
                }}
                disabled={!min}
              />
              {!!isMaxSmaller && (
                <span>
                  <p className="text-red-500 text-xs ml-1">
                    Max should be bigger
                  </p>
                </span>
              )}
              {!!isMaxBiggerThanMaxValue && (
                <span>
                  <p className="text-red-500 text-xs ml-1">
                    Max should be less than {maxValue}
                  </p>
                </span>
              )}
            </div>
          </div>
        )}

        {isAvgTgAccountAge && (
          <div className="flex gap-2">
            <InputField
              variant="auth"
              extra=""
              placeholder="Enter from AVG account age"
              id="fromDate"
              type="date"
              value={dateFrom}
              onChange={handleChooseFromDate}
              max={new Date().toISOString().split('T')[0]}
            />
            <div>
              <InputField
                variant="auth"
                extra=""
                state={isDateToSmaller ? 'error' : 'default'}
                placeholder="Enter to AVG account age"
                id="toDate"
                type="date"
                value={dateTo}
                onChange={handleChooseToDate}
                disabled={!dateFrom}
                max={new Date().toISOString().split('T')[0]}
              />
              <span>
                {isDateToSmaller && (
                  <p className="text-red-500 text-xs ml-1">
                    Date to should be bigger
                  </p>
                )}
              </span>
            </div>
          </div>
        )}
      </div>

      {isDataLoading ? (
        <p className="ml-auto">Loading...</p>
      ) : (
        <>
          {count !== null &&
            count >= 0 &&
            !isMaxSmaller &&
            !isDateToSmaller && (
              <div className="ml-auto min-w-[150px]">
                <UserCountAndPercent
                  count={count}
                  percent={(count / totalUsers) * 100}
                />
              </div>
            )}
        </>
      )}
    </div>
  );
};

type TExtraAnaliticsFieldsProps = {
  resourceId: string;
  type: TAnaliticsTypes;
  totalUsers: number;
};

export const ExtraAnaliticsFields = ({
  resourceId,
  type,
  totalUsers,
}: TExtraAnaliticsFieldsProps) => {
  return (
    <article className="flex flex-col gap-4 max-w-max">
      <h4 className="font-semibold mb-4 text-lg">Extra Analytics</h4>

      <ExtraAnaliticField
        title="Avarage User's Telegram Account Age:"
        dataType="accountAge"
        analityctsType={type}
        resourceId={resourceId}
        totalUsers={totalUsers}
      />
      <ExtraAnaliticField
        title="Age:"
        dataType="age"
        analityctsType={type}
        resourceId={resourceId}
        totalUsers={totalUsers}
        floatAllowed={true}
        maxValue={100}
      />
      <ExtraAnaliticField
        title="Completed Tasks:"
        dataType="completedTasks"
        analityctsType={type}
        resourceId={resourceId}
        totalUsers={totalUsers}
      />
      <ExtraAnaliticField
        title="Earned Points:"
        dataType="points"
        analityctsType={type}
        resourceId={resourceId}
        totalUsers={totalUsers}
        floatAllowed={true}
      />
      <ExtraAnaliticField
        title="Referrals:"
        dataType="referrals"
        analityctsType={type}
        resourceId={resourceId}
        totalUsers={totalUsers}
      />
    </article>
  );
};
