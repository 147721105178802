import { ButtonSM } from 'components/button/Small';
import { useState } from 'react';
import { giveawayService } from 'views/services/giveawayService';
import { Giveaway } from 'views/types/tasks';

export type TTaskDeleteProps = {
  giveaway: Giveaway;
};

export const TaskDelete = ({ giveaway }: TTaskDeleteProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleDelete = async () => {
    setIsSubmitting(true);
    try {
      if (window.confirm(`Are you sure you want to delete?`)) {
        await giveawayService.deleteGiveaway(giveaway.id);
      }
    } catch (e) {
      console.warn(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ButtonSM variant="danger" disabled={isSubmitting} onClick={handleDelete}>
      Delete
    </ButtonSM>
  );
};
