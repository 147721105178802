export const MAX_FILE_SIZE_BYTES = 5 * 1024 * 1024;

const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/jfif',
  'image/jpe',
  'image/png',
  'image/webp',
];

const checkImageFormat = (file: File): boolean => {
  return SUPPORTED_FORMATS.includes(file.type);
};

export async function checkImage(file: File): Promise<void> {
  return new Promise((resolve, reject): void => {
    if (!checkImageFormat(file)) {
      reject();
      return;
    }
    const img = new Image();

    img.onload = function (): void {
      resolve();
    };

    img.onerror = function (): void {
      reject('Error uploading image file.');
    };

    img.src = URL.createObjectURL(file);
  });
}
