import * as Dialog from '@radix-ui/react-dialog';
import { MdClose } from 'react-icons/md';

import clsx from 'clsx';
import {
  EAnaliticDataTypeEnum,
  useAnaliticsStore,
} from 'views/store/useAnaliticsStore';
import { useEffect, useState } from 'react';
import { analiticsService, TAnaliticsData } from 'views/services/analitics';
import { NonAppAnalitics } from './NonAppAnalitics';
import { utmService } from 'views/services/utmService';
import { TUTMAnalyticsData } from 'views/types/analytics';
import { AppStasistics } from './AppStatistic';

export const AnaliticsDialog = () => {
  const {
    id,
    type,
    setId,
    loading,
    setLoading,
    title,
    setTitle,
    appStatistics,
  } = useAnaliticsStore();
  const [data, setData] = useState<TAnaliticsData | TUTMAnalyticsData | null>(
    null
  );

  useEffect(() => {
    if (id) {
      if (type === EAnaliticDataTypeEnum.giveaway) {
        setLoading(true);
        analiticsService
          .getGiveawayStats(id)
          .then(setData)
          .finally(() => setLoading(false));
      }

      if (type === EAnaliticDataTypeEnum.task) {
        setLoading(true);
        analiticsService
          .getTasksStats(id)
          .then(setData)
          .finally(() => setLoading(false));
      }
      if (type === EAnaliticDataTypeEnum.utm) {
        setLoading(true);
        utmService
          .getUTMStats(id)
          .then(setData)
          .finally(() => setLoading(false));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleClose = () => {
    setData(null);
    setId(null);
    setTitle('');
  };

  const getAnaliticsType = (type: EAnaliticDataTypeEnum) => {
    switch (type) {
      case EAnaliticDataTypeEnum.task:
        return 'task';
      case EAnaliticDataTypeEnum.giveaway:
        return 'giveaway';
      case EAnaliticDataTypeEnum.utm:
        return 'utmSource';
      case EAnaliticDataTypeEnum.referral:
        return 'empty';
      default:
        return 'empty';
    }
  };

  const isThereData =
    (data && 'completedUsersCount' in data && data?.completedUsersCount > 0) ||
    (data && 'totalUsersCount' in data && data?.totalUsersCount > 0);

  const isAppStatistics = type === EAnaliticDataTypeEnum.app;

  return (
    <>
      <Dialog.Root open={!!id} onOpenChange={handleClose} modal>
        <Dialog.Portal>
          <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 z-50 bg-gray-800 bg-opacity-50" />
          <Dialog.Content
            onInteractOutside={e => {
              if ((e.target as HTMLElement).closest('.chakra-toast')) {
                e.preventDefault();
              }
            }}
            className={clsx(
              'data-[state=open]:animate-contentShow fixed left-[50%] top-[50%] z-[60] max-h-[85vh] w-[90vw] max-w-[800px]',
              'translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px]',
              'shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px]',
              'focus:outline-none dark:bg-gray-800 dark:text-white',
              'flex'
            )}
          >
            {loading && <p>Loading...</p>}
            {!isThereData && !loading && !isAppStatistics && <p>No data</p>}
            {isThereData && !isAppStatistics && (
              <NonAppAnalitics
                data={data}
                id={id}
                type={type}
                extraAnaliticsType={getAnaliticsType(type)}
                title={title}
              />
            )}

            {isAppStatistics && appStatistics && (
              <AppStasistics data={appStatistics} />
            )}

            <Dialog.Close asChild>
              <button
                id="task-modal-close-btn"
                type="button"
                className="text-violet11 hover:bg-violet4 focus:shadow-violet7 absolute right-[10px] top-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
                aria-label="Close"
              >
                <MdClose />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};
