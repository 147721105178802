import { InputHTMLAttributes, forwardRef, useId } from 'react';
import { twMerge } from 'tailwind-merge';

interface SelectProps extends InputHTMLAttributes<HTMLSelectElement> {
  label?: string;
  extra?: string;
  variant?: string;
  state?: string;
  disabled?: boolean;
  type?: string;
  children: React.ReactNode;
}

const Select = forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
  const id = useId();
  const { label, extra, variant, state, disabled, className, ...inputProps } =
    props;

  return (
    <div className={`${extra}`}>
      {!!label && (
        <label
          htmlFor={id}
          className={`text-sm text-navy-700 dark:text-white ${
            variant === 'auth' ? 'ml-1.5 font-medium' : 'ml-3 font-bold'
          }`}
        >
          {label}
        </label>
      )}
      <select
        disabled={disabled}
        id={id}
        className={twMerge(
          `mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 dark:bg-[#252f40] p-3 text-sm outline-none transition-opacity duration-300 ${
            disabled === true
              ? 'opacity-50'
              : state === 'error'
                ? 'border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400'
                : state === 'success'
                  ? 'border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400'
                  : 'border-gray-200 dark:!border-white/10 dark:text-white'
          }`,
          className
        )}
        ref={ref}
        {...inputProps}
      >
        {props.children}
      </select>
    </div>
  );
});

export default Select;
