import { ButtonSM } from 'components/button/Small';
import { useState } from 'react';

type TDeleteButtonProps = {
  action: () => Promise<void>;
  onSuccess: () => void;
  title?: string;
};

export const DeleteButton = ({
  action,
  onSuccess,
  title = 'Are you sure you want to delete?',
}: TDeleteButtonProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleDelete = async () => {
    setIsSubmitting(true);
    try {
      if (window.confirm(title)) {
        await action();
        onSuccess();
      }
    } catch (e) {
      console.warn(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ButtonSM variant="danger" disabled={isSubmitting} onClick={handleDelete}>
      Delete
    </ButtonSM>
  );
};
