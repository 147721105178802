import { createStandaloneToast } from '@chakra-ui/toast';
import { AxiosError } from 'axios';
import {
  EPlatforms,
  InfluencerTask,
  Task,
  TaskCategoriesEnum,
  TaskStatus,
  TaskType,
  TelegramTaskType,
} from 'views/types/tasks';
import { $api } from './interceptor';
import { TaskFormScheme } from 'views/admin/marketplace/scheme/taksForm';
import { giveawayService } from './giveawayService';

export type TTicketTask = {
  starsPrice: number;
  ticketsAmount: number;
};

export type TOwnTicketTask = {
  ticketsAmount: string;
};

export type TOwnTicketTaskPayload = {
  ticketsAmount: number;
};

export type TTrafficTask = {
  partnerUrl: string;
};

export interface TaskPayload {
  redirectUrl?: string;
  category?: TaskCategoriesEnum;
  title: string;
  description: string;
  inviteCount?: number;
  targetLanguages?: string[];
  targetCountryIds?: string[];
  type: TaskType;
  rewardPointsAmount?: number;
  order?: number | null;
  iconUrl?: string | null;
  status: TaskStatus;
  isTgPremiumOnly: boolean;
  isTgStandardOnly: boolean;
  onlyForIos?: boolean;
  isUsersWithWalletOnly: boolean;
  isUsersWithoutWalletOnly: boolean;
  targetPlatforms?: EPlatforms[];
  userCriteria: {
    isPremiumCriteria?: boolean;
    devicesCriteria?: string[];
    countriesCriteria?: string[];
    languageCriteria?: string[];
  };
  rewardTicketsAmount?: number;
  projectId?: string;
  customRewards?: string[];
  giveawayId?: string;
  maxSuccessfullyCompletions?: number;
  telegramTask?: {
    telegramResourceId: number;
    type: TelegramTaskType;
  };
  youtubeTask?: {
    secretWord: string;
  };
  buyTicketTask?: TTicketTask;
  ownTicketTask?: TOwnTicketTaskPayload;
  influencerTasks?: InfluencerTask[];
  trafficTask?: TTrafficTask;
}

type TAnswer = {
  text: string;
  correct: boolean;
};

export type TQuestion = {
  question: string;
  text?: string;
  answers: TAnswer[];
  description: string;
};

export type TQuizItem = {
  approximateTime: number;
  questions: TQuestion[];
};

export type TQuizPayload = {
  task: TaskPayload;
  quiz: TQuizItem;
};
export type TStoryPayload = {
  task: TaskPayload;
  storyTask: {
    story: TStory[];
  };
};

export type TTransactionPayload = {
  task: TaskPayload;
  transactionTask: {
    transactionWalletAddress: string;
    transactionAmount: number;
    provider?: string;
  };
};

export interface TaskDetailsState {
  description: string;
  availableFromLevel: number;
  title: string;
  rewardPointsAmount: number;
  isActive: boolean;
  redirectUrl?: string;
  type: string;
  isFeaturedTask: boolean;
  iconUrl?: string;
  relativeRewardPercent?: number;
  projectId?: number;
}

export type TStory = {
  imageUrl: string;
  order: number;
};

export type TStoryTask = {
  stories: TStory[];
};

export type TUserInfluencer = {
  id: string;
  username: string;
  referralCount: number;
};

export type SetPagesCount = (count: number) => void;

export const { toast } = createStandaloneToast();

const tasksApi = () => {
  const getTasks = async () => {
    const { data } = await $api.get('/api/tasks/list');
    return data;
  };

  const createTaskSubmit = async (
    data: TaskFormScheme,
    giveawayID?: string
  ) => {
    const iconUrl = data.task.iconUrl;

    const payload: {
      task: TaskPayload;
      influencerTasks?: InfluencerTask[];
      targetCountries?: { id: string; code: string; name: string }[];
      targetLanguages?: { code: string }[];
    } = {
      task: {
        category: data.task?.category,
        targetCountryIds: Object.entries(data.task?.targetCountries)
          .filter(([_, value]) => value)
          .map(([key, _]) => key),
        targetLanguages: Object.entries(data.task?.targetLanguages)
          .filter(([_, value]) => value)
          .map(([key, _]) => key),
        title: data.task.title,
        description: data.task.description,
        type: data.task.type,
        rewardPointsAmount: +data.task.rewardPointsAmount,
        order: data.task?.order ? +data.task?.order : 0,
        iconUrl: iconUrl ? iconUrl : undefined,
        isTgPremiumOnly: data.task?.isTgPremiumOnly,
        isTgStandardOnly: data.task?.isTgStandardOnly,
        isUsersWithWalletOnly: data.task?.isUsersWithWalletOnly,
        isUsersWithoutWalletOnly: data.task?.isUsersWithoutWalletOnly,
        userCriteria: data.task?.userCriteria,
        redirectUrl: data.task?.redirectUrl,
        targetPlatforms: data.task?.onlyForIos ? [EPlatforms.ios] : [],
        inviteCount: data.referralTask?.inviteCount
          ? +data.referralTask.inviteCount
          : undefined,
        status: data.task?.isActive ? TaskStatus.ACTIVE : TaskStatus.INACTIVE,
        giveawayId: giveawayID,
        influencerTasks:
          !giveawayID && !!data?.influencerTasks?.length
            ? (data?.influencerTasks || []).filter(task => task.influencerId)
            : undefined,
      },
    };

    switch (data.task.type) {
      case TaskType.REFERRAL:
        await postReferralTask(payload, +data.referralTask.inviteCount);
        return;
      case TaskType.TELEGRAM:
        payload.task.telegramTask = {
          telegramResourceId: +data.telegramTask.resourceId,
          type: TelegramTaskType.SUBSCRIBE,
        };
        await postTelegramTask(payload);
        return;
      case TaskType.YOUTUBE:
        payload.task.youtubeTask = {
          secretWord: data.youtubeTask.secretWord,
        };
        await postYoutubeTask(payload);
        return;
      case TaskType.BUYTICKETS:
        payload.task.buyTicketTask = {
          starsPrice: data.buyTicketTask.starsPrice,
          ticketsAmount: data.buyTicketTask.ticketsAmount,
        };
        await postTicketsTask(payload);
        return;
      case TaskType.OWNTICKETS:
        payload.task.ownTicketTask = {
          ticketsAmount: +data.ownTicketTask.ticketsAmount,
        };
        await postOwnTicketsTask(payload);
        return;
      case TaskType.TRAFFIC:
        payload.task.trafficTask = {
          partnerUrl: data.trafficTask.partnerUrl,
        };
        await postTrafficTask(payload);
        return;

      case TaskType.SNAPSHOT:
        await postSnapshotTask(payload.task);
        break;

      case TaskType.COMMON:
      case TaskType.WALLET:
        await postCommonTask(payload.task);
        break;

      case TaskType.QUIZ:
        await postQuizTask({ task: payload.task, quiz: data.quiz });
        break;

      case TaskType.STORY:
        await postStoryTask({ task: payload.task, storyTask: data.storyTask });
        break;

      case TaskType.TRANSACTION:
        await postTransactionTask({
          task: payload.task,
          transactionTask: {
            transactionAmount: +data.transactionAmount,
            transactionWalletAddress: data.transactionWalletAddress,
          },
        });
        break;
      default:
        return;
    }
  };

  const getTasksFilters = async () => {
    try {
      const { data } = await $api.get('/api/users/target-filters');

      return data;
    } catch (e) {
      console.log(e);
    }
  };

  const getPaginatedTasks = async ({
    page = 0,
    pageSize = 10,
    searchValue,
  }: {
    page?: number;
    pageSize?: number;
    searchValue?: string;
  }) => {
    const { data } = await $api.get('/api/tasks/list', {
      params: { page: page + 1, limit: pageSize, search: searchValue },
    });

    return data;
  };

  const patchTask = async (
    taskID: string,
    data: TaskFormScheme,
    giveawayID?: string
  ) => {
    const iconUrl = data.task.iconUrl;
    const isOwnTickets = data.task.type === TaskType.OWNTICKETS;
    const requestBody: Record<string, unknown> = {
      enabled: data.task.isActive,
      category: data.task?.category,
      giveawayID: giveawayID,
      description: data.task.description,
      targetPlatforms: data.task?.onlyForIos ? [EPlatforms.ios] : [],
      title: data.task.title,
      rewardPointsAmount: +data.task.rewardPointsAmount,
      redirectUrl: data.task.redirectUrl,
      targetCountryIds: Object.entries(data.task.targetCountries)
        .filter(([_, value]) => value)
        .map(([key, _]) => key),
      targetLanguages: Object.entries(data.task.targetLanguages)
        .filter(([_, value]) => value)
        .map(([key, _]) => key),
      isTgPremiumOnly: data.task.isTgPremiumOnly,
      isTgStandardOnly: data.task?.isTgStandardOnly,
      isUsersWithWalletOnly: data.task.isUsersWithWalletOnly,
      isUsersWithoutWalletOnly: data.task.isUsersWithoutWalletOnly,
      status: data.task.isActive ? TaskStatus.ACTIVE : TaskStatus.INACTIVE,
      iconUrl: iconUrl ? iconUrl : null,
      order: data.task.order ? +data.task.order : 0,
      inviteCount: data.referralTask.inviteCount
        ? +data.referralTask.inviteCount
        : undefined,
      secretWord: data.youtubeTask.secretWord,
      starsPrice: +data.buyTicketTask.starsPrice,
      ticketsAmount: isOwnTickets
        ? +data.ownTicketTask.ticketsAmount
        : +data.buyTicketTask.ticketsAmount,
      partnerUrl: data.trafficTask.partnerUrl,
      stories: data?.storyTask?.story?.filter(story => story.imageUrl)?.length
        ? data?.storyTask?.story
        : undefined,
      quiz:
        data.task.type === TaskType.QUIZ
          ? {
              approximateTime: +data?.quiz?.approximateTime,
              questions: data?.quiz?.questions,
            }
          : undefined,
      telegramResourceId: data?.telegramTask?.resourceId
        ? +data.telegramTask.resourceId
        : undefined,
      influencerTasks:
        !giveawayID && !!data?.influencerTasks?.length
          ? (data?.influencerTasks || []).filter(task => task.influencerId)
          : undefined,
      ...(data?.transactionAmount
        ? { transactionAmount: +data?.transactionAmount }
        : {}),
      ...(data?.transactionWalletAddress
        ? { transactionWalletAddress: data?.transactionWalletAddress }
        : {}),
    };

    try {
      await $api.patch(`api/tasks/${taskID}`, requestBody);

      toast({
        title: 'Task updated.',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: 'Error occured.',
          description: error.response?.data.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      } else if (error instanceof Error) {
        toast({
          title: 'Error occured.',
          description: error.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }
      throw error;
    }
  };

  const getInfluencer = async (username: string) => {
    try {
      return await $api.get<TUserInfluencer>(
        `/api/users/influencer/${username}`
      );
    } catch (e) {
      console.log(e);
    }
  };

  const getUTMInfluencer = async ({
    username,
    includeZeroReferrals = false,
  }: {
    username: string;
    includeZeroReferrals?: boolean;
  }) => {
    try {
      return await $api.get<TUserInfluencer>(
        `/api/users/influencer/${username}`,
        {
          params: {
            ...(includeZeroReferrals ? { includeZeroReferrals } : {}),
          },
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  const deleteTask = async (taskID: string, isGiveaway = false) => {
    try {
      const response = await $api.delete(`api/tasks/${taskID}`);
      if (response.status === 200) {
        toast({
          title: 'Task successfully deleted.',
          status: 'success',
          isClosable: true,
          position: 'top-right',
        });

        if (isGiveaway) {
          giveawayService.getGiveaways();
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: 'Error',
          description: error.response?.data.message,
          status: 'error',
        });
      }
      throw error;
    }
  };

  const postSnapshotTask = async (payload: TaskPayload) => {
    try {
      await $api.post('/api/tasks/snapshot', payload);
      toast({
        title: 'Snapshot task successfully created.',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: 'Error occured.',
          description: error.response?.data.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      } else if (error instanceof Error) {
        toast({
          title: 'Error occured.',
          description: error.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }
      throw error;
    }
  };

  const postCommonTask = async (payload: TaskPayload) => {
    try {
      await $api.post('/api/tasks/common', payload);
      toast({
        title: 'Common task successfully created.',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: 'Error occured.',
          description: error.response?.data.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      } else if (error instanceof Error) {
        toast({
          title: 'Error occured.',
          description: error.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }
      throw error;
    }
  };

  const postQuizTask = async (payload: TQuizPayload) => {
    const formattedPayload: TQuizPayload = {
      ...payload,
      quiz: {
        ...payload.quiz,
        approximateTime: +payload.quiz.approximateTime,
      },
    };
    try {
      await $api.post('/api/tasks/quiz', formattedPayload);
      toast({
        title: 'Quiz task successfully created.',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: 'Error occured.',
          description: error.response?.data.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      } else if (error instanceof Error) {
        toast({
          title: 'Error occured.',
          description: error.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }
      throw error;
    }
  };
  const postStoryTask = async (payload: TStoryPayload) => {
    const formattedPayload: TStoryPayload = {
      task: {
        ...payload.task,
        title: 'Story',
        description: 'Story',
      },
      storyTask: {
        ...payload.storyTask,
      },
    };
    try {
      await $api.post('/api/tasks/story', formattedPayload);
      toast({
        title: 'Stories successfully created.',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: 'Error occured.',
          description: error.response?.data.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      } else if (error instanceof Error) {
        toast({
          title: 'Error occured.',
          description: error.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }
      throw error;
    }
  };

  const postTransactionTask = async (payload: TTransactionPayload) => {
    const formattedPayload: TTransactionPayload = {
      task: {
        ...payload.task,
      },
      transactionTask: {
        ...payload.transactionTask,
        provider: 'TON',
      },
    };
    try {
      await $api.post('/api/tasks/transaction', formattedPayload);
      toast({
        title: 'Transaction task has been successfully created.',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: 'Error occured.',
          description: error.response?.data.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      } else if (error instanceof Error) {
        toast({
          title: 'Error occured.',
          description: error.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }
      throw error;
    }
  };

  const postTelegramTask = async (payload: { task: TaskPayload }) => {
    try {
      await $api.post('/api/tasks/telegram', {
        task: payload.task,
        telegramTask: {
          telegramResourceId: payload.task.telegramTask?.telegramResourceId,
          type: payload.task.telegramTask?.type,
        },
      });
      toast({
        title: 'Telegram task successfully created.',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: 'Error occurred.',
          description: error.response?.data.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      } else if (error instanceof Error) {
        toast({
          title: 'Error occurred.',
          description: error.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }
      throw error;
    }
  };
  const postYoutubeTask = async (payload: { task: TaskPayload }) => {
    try {
      await $api.post('/api/tasks/youtube', {
        task: payload.task,
        youtubeTask: {
          secretWord: payload.task.youtubeTask?.secretWord,
        },
      });
      toast({
        title: 'Yotube task successfully created.',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: 'Error occurred.',
          description: error.response?.data.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      } else if (error instanceof Error) {
        toast({
          title: 'Error occurred.',
          description: error.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }
      throw error;
    }
  };
  const postTicketsTask = async (payload: { task: TaskPayload }) => {
    try {
      await $api.post('/api/tasks/buy-ticket', {
        task: payload.task,
        buyTicketTask: {
          starsPrice: +payload.task.buyTicketTask?.starsPrice,
          ticketsAmount: +payload.task.buyTicketTask?.ticketsAmount,
        },
      });
      toast({
        title: 'Buy tickets task successfully created.',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: 'Error occurred.',
          description: error.response?.data.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      } else if (error instanceof Error) {
        toast({
          title: 'Error occurred.',
          description: error.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }
      throw error;
    }
  };

  const postOwnTicketsTask = async (payload: { task: TaskPayload }) => {
    try {
      await $api.post('/api/tasks/own-ticket', {
        task: payload.task,
        ownTicketTask: {
          ticketsAmount: payload.task.ownTicketTask.ticketsAmount,
        },
      });
      toast({
        title: 'Own tickets task successfully created.',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: 'Error occurred.',
          description: error.response?.data.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      } else if (error instanceof Error) {
        toast({
          title: 'Error occurred.',
          description: error.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }
      throw error;
    }
  };

  const postTrafficTask = async (payload: { task: TaskPayload }) => {
    try {
      await $api.post('/api/tasks/traffic', {
        task: payload.task,
        trafficTask: {
          partnerUrl: payload.task.trafficTask.partnerUrl,
        },
      });
      toast({
        title: 'Traffic task successfully created.',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: 'Error occurred.',
          description: error.response?.data.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      } else if (error instanceof Error) {
        toast({
          title: 'Error occurred.',
          description: error.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }
      throw error;
    }
  };

  const postReferralTask = async (
    payload: { task: TaskPayload },
    count: number
  ) => {
    try {
      await $api.post('/api/tasks/referral', {
        task: payload.task,
        referralTask: {
          inviteCount: count,
        },
      });
      toast({
        title: 'Referral task successfully created.',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: 'Error occurred.',
          description: error.response?.data.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      } else if (error instanceof Error) {
        toast({
          title: 'Error occurred.',
          description: error.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }
      throw error;
    }
  };

  const handleSubmitOrder = async (order: Record<Task['id'], string>) => {
    try {
      const entries = Object.entries(order);

      for (let i = 0; i < entries.length; i++) {
        await $api.patch(`api/tasks/${entries[i][0]}`, {
          order: +entries[i][1],
        });
      }

      toast({
        title: 'Order updated.',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: 'Error occured.',
          description: error.response?.data.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      } else if (error instanceof Error) {
        toast({
          title: 'Error occured.',
          description: error.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }
      throw error;
    }
  };

  const createMessage = async ({
    message,
    inactiveDays,
    withoutGiveawayOnly,
    giveawayId,
    images,
  }: {
    message: string;
    giveawayId?: string;
    inactiveDays?: number;
    withoutGiveawayOnly?: boolean;
    images?: File[];
  }) => {
    const url = '/api/telegram/send';
    const formData = new FormData();
    formData.append('message', message);

    if (!isNaN(inactiveDays)) {
      formData.append('inactiveDays', inactiveDays.toString());
    }

    if (withoutGiveawayOnly) {
      formData.append('withoutGiveawayOnly', 'true');
    }

    if (giveawayId) formData.append('giveawayId', giveawayId);

    if (images && images.length > 0) {
      images.forEach(image => {
        formData.append('images', image);
      });
    }

    try {
      await $api.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return true;
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: 'Error occured.',
          description: error.response?.data.message || error.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      } else if (error instanceof Error) {
        toast({
          title: 'Error occured.',
          description: error.message,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }
      return false;
    }
  };

  return {
    getTasks,
    deleteTask,
    createTaskSubmit,
    patchTask,
    getPaginatedTasks,
    getInfluencer,
    getTasksFilters,
    handleSubmitOrder,
    createMessage,
    getUTMInfluencer,
  };
};

export const tasksService = tasksApi();
