import clsx from 'clsx';

type TDurovFaceRendererProps = {
  index: number;
  bgColor?: string;
  img?: string;
};

export const DurovFaceRenderer = ({
  index,
  bgColor,
  img,
}: TDurovFaceRendererProps) => {
  return (
    <div
      className={clsx(
        'flex-1 w-[60px] h-[60px] rounded-[10px] flex items-center justify-center border-[4px]'
      )}
      style={{ backgroundColor: bgColor ?? '#99C9D7' }}
    >
      {img ? (
        <div dangerouslySetInnerHTML={{ __html: img }}></div>
      ) : (
        <span className="font-medium text-[#57575740] text-[15px]">
          {index}
        </span>
      )}
    </div>
  );
};
