import { create, useStore } from 'zustand';

type TSettingsStore = {
  isAutoApproveEnabled: boolean;
};

export const settingsStore = create<TSettingsStore>(_set => ({
  isAutoApproveEnabled: false,
}));

export const useSettingsStore = () => useStore(settingsStore);
