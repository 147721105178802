import { formatNumber } from 'utils/formatNumber';
import { generateColors } from 'utils/generateColors';
import { CustomChart } from './PieChart';
import { useMemo } from 'react';

type FieldWithChartProps = {
  data: {
    name: string;
    usersCount: number;
    usersPercent: number;
  }[];
};

export const FieldWithChart = ({ data }: FieldWithChartProps) => {
  const colors = useMemo(() => generateColors(data.length), [data.length]);
  const chartData = data.map((item, i) => ({
    title: item.name,
    value: item.usersCount,
    percent: item.usersPercent,
    color: colors[i],
  }));
  return (
    <div className="flex gap-4">
      <CustomChart data={chartData} />

      <ul className="flex flex-col gap-1 max-h-[200px] overflow-y-auto pr-4">
        {chartData.map((item, index) => (
          <li key={index} className="flex gap-2 items-center">
            <span
              className="w-4 h-4 rounded-full"
              style={{ backgroundColor: item.color }}
            ></span>
            <p className="font-bold">{item.title}</p>
            <p>
              {formatNumber(item.value)} ({formatNumber(item.percent)}%)
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};
