import axios from 'axios';
import { signInService } from './auth';

export const accessToken = localStorage.getItem('accessToken') || '';

export const API_URL = process.env.REACT_APP_API_URL;

export const $api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

$api.interceptors.request.use(config => {
  const accessToken = localStorage.getItem('accessToken');
  config.headers['Authorization'] = `${accessToken}`;
  return config;
});

$api.interceptors.response.use(
  config => {
    return config;
  },
  async error => {
    const originalRequest = error.config;
    if (
      error.response?.status === 401 &&
      error.config &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        await signInService.sendRefreshRequest();
        return $api.request(originalRequest);
      } catch (_) {
        localStorage.removeItem('accessToken');
        window.location.replace('/#/');
      }
    }
    throw error;
  }
);
