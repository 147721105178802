import { createStandaloneToast } from '@chakra-ui/toast';
import { $api } from './interceptor';
import { AxiosError } from 'axios';
import { TUTMAnalyticsData } from 'views/types/analytics';
import { transformUTMName } from 'utils/utm';

export const { toast } = createStandaloneToast();

const utmSourceApi = () => {
  const getPaginatedUTMSources = async ({
    page = 0,
    pageSize = 10,
    searchValue,
    isInfluencersSources,
  }: {
    page?: number;
    pageSize?: number;
    searchValue?: string;
    isInfluencersSources?: boolean;
  }) => {
    const { data } = await $api.get('/api/utm-sources', {
      params: {
        page: page + 1,
        limit: pageSize,
        search: searchValue,
        ...(typeof isInfluencersSources == 'boolean'
          ? {
              'filter.influencer_id': isInfluencersSources
                ? '$not:$null'
                : '$null',
            }
          : {}),
      },
    });

    return data;
  };

  const getUTMStats = async (id: string) => {
    const { data } = await $api.get<TUTMAnalyticsData>(
      `/api/utm-sources/stat/${id}`
    );
    return data;
  };

  const createUTMSource = async (data: {
    name: string;
    influencerId?: string;
  }) => {
    try {
      await $api.post('/api/utm-sources', {
        name: transformUTMName(data.name),
        ...(data?.influencerId
          ? {
              influencerId: data.influencerId,
            }
          : {}),
      });
      toast({
        title: 'UTM Source created',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: error.response?.data.message || 'Error creating UTM Source',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } else {
        toast({
          title: 'Error creating UTM Source',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
      throw error;
    }
  };

  const editUTMSource = async (id: string, data: { name: string }) => {
    try {
      await $api.patch(`/api/utm-sources/${id}`, {
        name: transformUTMName(data.name),
      });
      toast({
        title: 'UTM Source updated',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: error.response?.data.message || 'Error updating UTM Source',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } else {
        toast({
          title: 'Error updating UTM Source',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
      throw error;
    }
  };

  const deleteUTMSource = async (id: string) => {
    try {
      await $api.delete(`/api/utm-sources/${id}`);
      toast({
        title: 'UTM Source deleted',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: error.response?.data.message || 'Error deleting UTM Source',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } else {
        toast({
          title: 'Error deleting UTM Source',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
      throw error;
    }
  };

  return {
    getPaginatedUTMSources,
    getUTMStats,
    createUTMSource,
    editUTMSource,
    deleteUTMSource,
  };
};

export const utmService = utmSourceApi();
