import { useMemo, useState } from 'react';
import { Cell, Pie, PieChart, Tooltip as ChartTooltip, Sector } from 'recharts';
import { formatNumber } from 'utils/formatNumber';
import { generateColors } from 'utils/generateColors';

type ChartData = {
  title: string;
  value: number;
  percent: number;
  color?: string;
};

type ChartProps = {
  data: ChartData[];
  width?: number;
  height?: number;
  innerRadius?: number;
  outerRadius?: number;
};

type CustomActiveShapeProps = {
  cx: number;
  cy: number;
  innerRadius: number;
  outerRadius: number;
  startAngle: number;
  endAngle: number;
  fill: string;
};

type TooltipPayload = {
  name: string;
  value: number;
  payload: ChartData;
};

type CustomTooltipProps = {
  active?: boolean;
  payload?: TooltipPayload[];
};

const CustomTooltip: React.FC<CustomTooltipProps> = props => {
  const { active, payload } = props;

  if (active && payload && payload.length) {
    const data = payload[0].payload;

    return (
      <div className="border border-gray-200 dark:!border-white/10 shadow-lg bg-white dark:bg-gray-800 boxShadow-darkinset p-4 rounded-xl">
        <div className="flex gap-2 w-max">
          <p>{data.title}</p>-
          <p>
            {data.value} ({formatNumber(data.percent)}%)
          </p>
        </div>
      </div>
    );
  }

  return null;
};

export const CustomChart = ({
  data,
  width = 200,
  height = 200,
  innerRadius = 0,
  outerRadius = 80,
}: ChartProps) => {
  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);

  const onPieEnter = (_data: unknown, index: number): void => {
    setActiveIndex(index);
  };

  const onPieLeave = () => {
    setActiveIndex(undefined);
  };
  const colors = useMemo(() => generateColors(data.length), [data]);

  const chartDataWithColors = useMemo(() => {
    const dataWithColors = [...data];

    for (let i = 0; i < dataWithColors.length; i++) {
      if (!dataWithColors[i].color) {
        dataWithColors[i].color = colors[i];
      }
    }

    return dataWithColors;
  }, [data, colors]);

  const renderActiveShape = (props: CustomActiveShapeProps) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
      props!;

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius + 5}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
          className="!outline-0 !border-0"
        />
      </g>
    );
  };

  return (
    <article className="w-fit">
      <PieChart width={width} height={height}>
        <Pie
          data={chartDataWithColors}
          activeIndex={activeIndex}
          // @ts-expect-error its ok
          activeShape={renderActiveShape}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          paddingAngle={0}
          onMouseEnter={onPieEnter}
          onMouseLeave={onPieLeave}
          dataKey="value"
          strokeWidth={0}
          startAngle={90}
          endAngle={-360}
          width={width}
          height={height}
        >
          {data.map((el, index) => (
            <Cell
              key={`cell-${index}`}
              fill={el.color}
              className="!outline-0 !border-0"
            />
          ))}
        </Pie>
        <ChartTooltip content={<CustomTooltip />} />
      </PieChart>
    </article>
  );
};
